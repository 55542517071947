<template>
  <div class="iss-main contactFrequency">
    <!--    <div class="gotoBack" @click="gotoBack">-->
    <!--      返回个人中心<RightOutlined class="ml-8" />-->
    <!--    </div>-->
    <a-tabs tab-position="left">
      <a-tab-pane key="base" tab="安全设置">
        <div class="iss-title">安全设置</div>
        <div class="iss-titleT">管理您的人脉宝盒账户</div>
        <a-list :dataSource="dataSource">
          <template #renderItem="{ item }">
            <a-list-item>
              <template #actions>
                <!--                 -->
                <a-button
                  v-if="item.key === 'mobile'"
                  class="update"
                  @click="handleClickUpdateMobile(item.description)"
                >
                  修改
                </a-button>
                <a-button
                  v-if="item.key === 'password'"
                  class="update"
                  @click="handleClickUpdatePassword(item.key)"
                >
                  修改
                </a-button>
                <div v-if="item.key === 'email'">
                  <!--       todo    根据email的值来看是绑定还是修改        -->
                  <a-button
                    v-if="item.description"
                    class="unbinding"
                    @click="handleClickEmail(item)"
                  >
                    修改
                  </a-button>
                  <a-button
                    v-else
                    class="unbinding"
                    @click="handleClickEmail(item)"
                  >
                    绑定
                  </a-button>
                </div>

                <div v-if="item.key === 'wechat'">
                  <a-button
                    v-if="item.state"
                    class="unbinding"
                    @click="handleClickWechat(item.key, 1)"
                  >
                    解绑
                  </a-button>
                  <a-button
                    v-else
                    class="unbinding"
                    @click="handleClickWechat(item.key, 0)"
                  >
                    绑定
                  </a-button>
                </div>
                <a-button
                  v-if="item.key === 'logout'"
                  class="logout"
                  @click="handleClickLogout(item.key)"
                >
                  注销
                </a-button>
              </template>
              <a-list-item-meta
                :title="item.title"
                :description="item.description"
              />
            </a-list-item>
          </template>
        </a-list>
      </a-tab-pane>
      <a-tab-pane key="saft" tab="通知">
        <div class="minHeigth">
          <div class="iss-title">通知</div>
          <div class="iss-titleT">
            管理您的通知，要更改推送通知，请打开人脉宝盒APP。
          </div>
          <div class="notice borBor">
            <div class="noticeL">
              <div class="noticeTO">公众号通知</div>
              <div class="noticeTT">当提醒到期时收到微信公众号通知。</div>
            </div>
            <div class="noticeR">
              <a-switch
                v-model:checked="publicAccountReminder"
                @click="handleClickSwitch()"
                checked-children="开"
                un-checked-children="关"
              />
            </div>
          </div>

          <div class="notice pt-24">
            <div class="noticeL">
              <div class="noticeTO">联系频次提前处理</div>
              <div class="noticeTT">
                当设置提前处理后将在联系频次周期前进行展示
              </div>
            </div>
            <div class="noticeR rigthR">
              <div class="dropDown" ref="selectBox">
                <div class="itemVal" @click="handleVal">
                  <img
                    src="@/assets/images/reminder/icons.png"
                    alt=""
                    class="iconsImg"
                  />
                  {{ itemVal }}
                </div>

                <div class="dropDownList" v-show="showList">
                  <div
                    v-for="item in earlyList"
                    :key="item"
                    class="dropDownItem"
                    @click="handleDropDownItem(item)"
                  >
                    {{ item.itemNameCn }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
  <a-modal
    v-model:visible="visible"
    title="修改密码"
    centered
    :destroyOnClose="true"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <!--   修改密码form  -->
    <a-form
      ref="passwordRef"
      :model="form.passwordForm"
      :rules="passwordRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <!--    v-bind="validateInfos.mobile"    -->
      <a-form-item label="手机号">
        <!--        <a-input-->
        <!--          autocomplete="off"-->
        <!--          v-model:value="form.passwordForm.mobile"-->
        <!--          placeholder="请输入手机号"-->
        <!--        />-->
        <div>{{ form.passwordForm.mobile }}</div>
      </a-form-item>
      <a-form-item v-bind="validateInfos.verifyCode" label="验证码">
        <a-row type="flex">
          <a-col :flex="1">
            <a-input
              auto-complete="new-password"
              autocomplete="new-password"
              v-model:value="form.passwordForm.verifyCode"
              placeholder="请输入验证码"
            />
          </a-col>
          <a-col class="ml-10">
            <a-button
              type="primary"
              :disabled="btnPsw.disabled"
              ghost
              @click="handleGetCodePsw"
            >
              {{ btnPsw.title }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item v-bind="validateInfos.password" label="新密码">
        <a-input-password
          auto-complete="new-password"
          autocomplete="new-password"
          v-model:value="form.passwordForm.password"
          placeholder="请输入新密码"
        />
      </a-form-item>
      <a-form-item v-bind="validateInfos.confirmPassword" label="确认密码">
        <a-input-password
          v-model:value="form.passwordForm.confirmPassword"
          placeholder="请输入确认密码"
        />
      </a-form-item>
    </a-form>
  </a-modal>

  <!--   手机号   -->
  <a-modal
    v-model:visible="visiblePE"
    :title="showSafe ? '安全验证' : '修改手机号'"
    centered
    @ok="handleSafe"
    :destroyOnClose="true"
  >
    <!--   修改手机form  -->
    <a-form
      v-if="showSafe"
      ref="safeRef"
      :model="safeForm"
      :rules="safeRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item label="原手机号">
        <div>{{ oldAccount }}</div>
      </a-form-item>
      <a-form-item name="oldCode" label="验证码">
        <a-row type="flex">
          <a-col :flex="1">
            <a-input
              v-model:value="safeForm.oldCode"
              placeholder="请输入验证码"
            />
          </a-col>
          <a-col class="ml-10">
            <a-button
              type="primary"
              :disabled="btnOne.disabled"
              ghost
              @click="handleGetCodeO"
            >
              {{ btnOne.title }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>

    <a-form
      v-else
      ref="safeRef"
      :model="safeForm"
      :rules="safeRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item name="account" label="新手机号码">
        <a-input
          autocomplete="off"
          v-model:value="safeForm.account"
          :placeholder="'请输入新手机号'"
        />
      </a-form-item>
      <a-form-item name="code" label="验证码">
        <a-row type="flex">
          <a-col :flex="1">
            <a-input v-model:value="safeForm.code" placeholder="请输入验证码" />
          </a-col>
          <a-col class="ml-10">
            <a-button
              type="primary"
              :disabled="btnT.disabled"
              ghost
              @click="handleGetCodeT"
            >
              {{ btnT.title }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>

    <template v-slot:footer>
      <a-button @click="close">取消</a-button>

      <a-button v-if="showSafe" type="primary" @click="gotoOk">
        下一步
      </a-button>
      <a-button v-else type="primary" @click="handlePhoneOk"> 确认 </a-button>
    </template>
  </a-modal>

  <email-page
    v-model:visible="emailPage"
    :init="activeEmailPage"
    @fnOk="handleEmailPage"
  />
  <bind-email
    v-model:visible="bindEmailPage"
    :init="activeBindEmailPage"
    @fnOk="handleBindEmailPage"
  />
  <bind-wechat
    :visible="bindWechatPage"
    :type="bindWechatType"
    @fnOk="handleBindWechatPage"
  />
  <logout-account
    v-model:visible="logoutAccountPage"
    :init="activeLogoutAccountPage"
    @fnOk="handleLogoutAccountPage"
  />
</template>

<script>
import { ref, onMounted, reactive, toRefs, toRaw } from 'vue';
import { Col, Form, Row, Tabs, message, List, Switch } from 'ant-design-vue';
import loginApi from '@/api/login';
import personalApi from '@/api/personal';
import { useStore } from 'vuex';
import { validMobile } from '@/utils/validation';
import { useRouter } from 'vue-router';
import emailPage from '@/views/setup/components/emailPage';
import bindEmail from '@/views/setup/components/bindEmail';
import bindWechat from './components/bindWechat.vue';
import logoutAccount from '@/views/setup/components/logoutAccount';
import contactAll from '@/api/contactAll';
import frequencyApi from '@/api/frequency';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
    AList: List,
    AListItem: List.Item,
    AListItemMeta: List.Item.Meta,
    emailPage,
    bindEmail,
    bindWechat,
    logoutAccount,
    ASwitch: Switch,
  },
  setup() {
    const formRef = ref();
    const passwordRef = ref();
    const selectBox = ref();
    const safeRef = ref();
    const router = useRouter();
    // const route = useRoute();
    const store = useStore();
    const userId = store.state.account.user.id;
    const state = reactive({
      publicAccountReminder: true,
      showList: false,
      earlyList: [],
      itemVal: '',
      confirmLoading: false,
      visible: false,
      visiblePE: false,
      showSafe: false,
      emailPage: false,
      bindEmailPage: false,
      bindWechatPage: false,
      bindWechatType: 0,
      activeEmailPage: {},
      activeBindEmailPage: {},
      logoutAccountPage: false,
      activeLogoutAccountPage: {},
      // `${desensitization(data.mobile)}`
      // `${desensitization(data.email)}`
      dataSource: [
        {
          key: 'mobile',
          title: '手机号',
          description: '',
        },
        {
          key: 'email',
          title: '电子邮箱',
          description: '',
          value: '',
        },
        {
          key: 'wechat',
          title: '微信',
          description: '用户名字',
          state: 0,
        },
        {
          key: 'password',
          title: '密码',
          description: '已设置',
        },
        {
          key: 'logout',
          title: '注销账户',
          description: '',
        },
      ],
      safeForm: { account: '', code: '', oldCode: '' },
      oldAccount: '',
      timer: null,
      timerOne: null,
      timerT: null,
      timerPsw: null,
      showVal: null,
      btn: {
        disabled: false,
        title: '获取验证码',
      },
      btnOne: {
        disabled: false,
        title: '获取验证码',
      },
      btnPsw: {
        disabled: false,
        title: '获取验证码',
      },
      btnT: {
        disabled: false,
        title: '获取验证码',
      },
      activeUpload: '',
    });
    const form = reactive({
      sex: '',
      passwordForm: {
        mobile: '',
        verifyCode: '',
        password: '',
        confirmPassword: '',
        areaCode: '86',
        id: userId,
      },
      safeForm: { account: '', code: '', oldCode: '' },
      id: userId ? userId : '',
      // area: null,
      userName: '',
      avatar: '',
    });

    const required = { required: true, whitespace: true, message: '不能为空' };
    // resetFields
    const { validateInfos, validate, resetFields } = Form.useForm(
      form.passwordForm,
      {
        password: [
          required,
          {
            pattern:
              /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
            message: '长度为8-20位，必须同时含有字母(大小写)、数字和符号',
          },
        ],
        confirmPassword: [
          required,
          {
            validator: (rule, value) => {
              if (value) {
                if (value === form.passwordForm.password) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('两次输入密码不匹配');
                }
              }
              return Promise.reject();
            },
          },
        ],
        verifyCode: [required],
        // mobile: [
        //   required,
        //   {
        //     validator: (rule, value) => {
        //       if (value) {
        //         if (validMobile(value)) {
        //           return Promise.resolve();
        //         }
        //         return Promise.reject('请输入合法的手机号码');
        //       }
        //       return Promise.reject();
        //     },
        //   },
        // ],
      }
    );
    // 基本信息校验
    const formRules = Form.useForm(form, {});

    const getUserInfo = () => {
      personalApi.getSecurityConfigList('', userId).then(data => {
        if (data) {
          // 修改密码默认手机
          form.passwordForm.mobile = data.mobile;
          state.dataSource.map(item => {
            if (item.key === 'mobile') {
              item.description = data.mobile;
            } else if (item.key === 'email') {
              item.value = data.email;
              if (data.email) {
                item.description = data.email;
              } else {
                item.description = '当待办到期时收到电子邮件。';
              }
            } else if (item.key === 'wechat') {
              if (data.unionIdStatus) {
                item.description = data.userName;
                item.state = 1;
              } else {
                item.description = '绑定微信账号，可便捷扫码登录。';
              }
            } else if (item.key === 'password') {
              if (data.passwordStatus) {
                item.description = '已设置';
              } else {
                item.description = '未设置';
              }
            } else {
              item.description = '注销您的人脉宝盒账户和所有数据';
            }
          });
        }
      });
    };
    const getUserNotify = userId => {
      personalApi.getUserNotifySettingList('', userId).then(data => {
        if (data) {
          // console.log('data66', data);
          state.publicAccountReminder = data.publicAccountReminder;
          // state.itemVal = data.daysBeforeReminder
          if (data.daysBeforeReminder) {
            let newList = [];
            let toStr = data.daysBeforeReminder.toString();
            state.earlyList.forEach(item => {
              if (item.itemCode === toStr) {
                return newList.push(item);
              }
            });
            state.itemVal = newList[0]?.itemNameEn;
          }
        }
      });
    };
    // todo 更新通知
    const updateSetting = parmas => {
      personalApi
        .updateSettingByUserId('', parmas)
        .then(res => {
          if (res) {
            message.success('操作成功');
            // 请求成功或者失败时关闭
            state.loading = false;
            //  刷新通知列表
            getUserNotify(userId);
          }
        })
        .catch(() => {
          state.loading = false;
        });
    };

    onMounted(() => {
      getUserInfo();
      getUserNotify(userId);

      document.addEventListener('click', e => {
        if (selectBox.value) {
          if (!selectBox.value.contains(e.target)) {
            state.showList = false;
            state.loading = false;
          }
        }
      });

      // 提前提醒时间
      contactAll.getTree('', { code: 'FREQUENCY_ADVANCE_TYPE' }).then(res => {
        if (res) {
          state.earlyList = res;
          getUserNotify(userId);

          // 查询当前用户通知
          // frequencyApi.getUserNotifySettingList('', userId).then(res => {
          //   if (res) {
          //     console.log('123', res);
          //     // 默认提前两天提醒
          //     if (res.daysBeforeReminder) {
          //       let newList = [];
          //       let toStr = res.daysBeforeReminder.toString();
          //       state.earlyList.forEach(item => {
          //         if (item.itemCode === toStr) {
          //           return newList.push(item);
          //         }
          //       });
          //       state.itemVal = newList[0].itemNameEn;
          //     }
          //   }
          // });
        }
      });
    });

    return {
      ...toRefs(state),
      form,
      formRef,
      selectBox,
      validateInfos,
      resetFields,
      formRules,
      safeRef,
      userId,
      safeRules: {
        account: [
          required,
          {
            validator: (rule, value) => {
              if (value) {
                if (validMobile(value)) {
                  return Promise.resolve();
                }
                return Promise.reject('请输入合法的手机号码');
              }
              return Promise.reject();
            },
          },
        ],
        code: required,
        oldCode: required,
      },
      handleClickEmail(item) {
        // 邮箱有值
        if (item.value) {
          state.emailPage = true;
          // todo 邮箱修改
          state.activeEmailPage = state.dataSource[0];
        } else {
          state.bindEmailPage = true;
          // 绑定邮箱
          state.activeBindEmailPage = item;
        }
      },
      handleClickWechat(item, type) {
        state.bindWechatPage = true;
        state.bindWechatType = type;
      },
      // 注销账号
      handleClickLogout() {
        state.activeLogoutAccountPage = {
          mobile: state.dataSource[0].description,
        };
        state.logoutAccountPage = true;
      },
      handleClickSwitch() {
        // 更改通知
        let parmas = {
          publicAccountReminder: state.publicAccountReminder,
          // daysBeforeReminder: '',
        };
        console.log('parmas', parmas);
        updateSetting(parmas);
      },

      gotoBack() {
        router.push({
          path: '/personal',
        });
      },
      handleClickBySave() {
        // 基础信息不做必填校验
        formRef.value.validate().then(() => {
          console.log('form', form);
          personalApi.update('personal:update', form).then(res => {
            if (res) {
              location.reload();
            }
          });
        });
      },
      handleClickUpdateMobile(item) {
        console.log('item', item);
        state.oldAccount = item;
        state.visiblePE = true;
        state.showSafe = true;
      },
      // 修改密码
      handleClickUpdatePassword() {
        state.visible = true;
        form.passwordForm.password = '';
        form.passwordForm.verifyCode = '';
        form.passwordForm.mobile = state.dataSource[0].description;
        state.btnPsw = { disabled: false, title: '获取验证码' };
      },
      gotoOk() {
        safeRef.value.validate().then(() => {
          // 校验验证码是否正确
          personalApi
            .verifyCodeByUserId('', {
              userId: userId,
              verifyCode: state.safeForm.oldCode,
            })
            .then(res => {
              if (res) {
                state.showSafe = false;
                // 点击一下步需要清空验证码和清除定时器
                clearInterval(state.timerOne);
                state.btnOne = { disabled: false, title: '获取验证码' };
                state.safeForm.oldCode = '';
              } else {
                return message.error('验证码错误，请重新输入');
              }
            });
        });
      },
      close() {
        state.visiblePE = false;

        // 确认需要清空验证码和清除定时器
        clearInterval(state.timerT);
        clearInterval(state.timerOne);
        state.safeForm = { account: '', code: '', oldCode: '' };
      },
      handleEmailPage(value) {
        state.emailPage = value;
        //   调去页面数据接口，刷新页面
        getUserInfo();
      },
      handleBindEmailPage(value) {
        state.bindEmailPage = value;
        //   调去页面数据接口，刷新页面
        getUserInfo();
      },

      handleBindWechatPage(value) {
        state.bindWechatPage = value;
        //   调去页面数据接口，刷新页面
        getUserInfo();
      },

      handleLogoutAccountPage(value) {
        state.logoutAccountPage = value;
      },

      handleVal() {
        state.showList = !state.showList;
        console.log('state.showList', state.showList);
      },
      handleDropDownItem(item) {
        state.loading = true;
        let parmas = {
          userId: store.state.account.user.id,
          daysBeforeReminder: item.itemCode,
        };
        frequencyApi.updateSettingByUserId('', parmas).then(res => {
          if (res) {
            console.log('res88', res);
            state.showList = false;
            state.itemNameCode = item.itemNameCode;
            state.itemVal = item.itemNameEn;
            message.success('修改频次成功');
            state.loading = false;
          }
        });
      },
      // 手机号安全验证 获取验证码
      handleGetCodeO() {
        safeRef.value.validateFields('account').then(() => {
          state.btnOne = { disabled: true, title: '重新获取(60s)' };
          let count = 60;
          state.timerOne = setInterval(() => {
            if (count === 1) {
              state.btnOne = { disabled: false, title: '获取验证码' };
              clearInterval(state.timerOne);
              state.timerOne = null;
            } else {
              count--;
              state.btnOne.title = `重新获取(${count}s)`;
            }
          }, 1000);

          personalApi.sendSmsVerifyCodeByUserId('', userId).then(res => {
            if (res) {
              message.success('验证码已发送，请注意查收');
            }
          });
        });
      },

      handleGetCodeT() {
        safeRef.value.validateFields('account').then(() => {
          state.btnT = { disabled: true, title: '重新获取(60s)' };
          let count = 60;
          state.timerT = setInterval(() => {
            if (count === 1) {
              state.btnT = { disabled: false, title: '获取验证码' };
              clearInterval(state.timerT);
              state.timerT = null;
            } else {
              count--;
              state.btnT.title = `重新获取(${count}s)`;
            }
          }, 1000);

          console.log('userId456', userId);
          loginApi
            .getCode(state.safeForm.account)
            .then(res => {
              if (res) {
                message.success('验证码已发送，请注意查收');
              }
            })
            .catch(() => {
              clearInterval(state.timerT);
              state.btnT = { disabled: false, title: '获取验证码' };
            });
        });
      },
      handleGetCodePsw() {
        // validate('').then(() => {
        state.btnPsw = { disabled: true, title: '重新获取(60s)' };
        let count = 60;
        state.timerPsw = setInterval(() => {
          if (count === 1) {
            state.btnPsw = { disabled: false, title: '获取验证码' };
            clearInterval(state.timerPsw);
            state.timerPsw = null;
          } else {
            count--;
            state.btnPsw.title = `重新获取(${count}s)`;
          }
        }, 1000);

        personalApi
          .sendSmsVerifyCodeByUserId('', userId)
          .then(res => {
            if (res) {
              message.success('验证码已发送，请注意查收');
            }
          })
          .catch(() => {
            clearInterval(state.timerPsw);
            state.btnPsw = { disabled: false, title: '获取验证码' };
          });
        // });
      },

      handleGetCode() {
        safeRef.value.validateFields('account').then(() => {
          state.btn = { disabled: true, title: '重新获取(60s)' };
          let count = 60;
          state.timer = setInterval(() => {
            if (count === 1) {
              state.btn = { disabled: false, title: '获取验证码' };
              clearInterval(state.timer);
              state.timer = null;
            } else {
              count--;
              state.btn.title = `重新获取(${count}s)`;
            }
          }, 1000);

          loginApi
            .getCode(state.safeForm.account)
            .then(res => {
              if (res) {
                message.success('验证码已发送，请注意查收');
              }
            })
            .catch(() => {
              clearInterval(state.timerT);
              state.btnT = { disabled: false, title: '获取验证码' };
            });
        });
      },
      handleOk() {
        validate().then(() => {
          if (state.loading) return;
          state.loading = true;
          loginApi
            .updateUserPassword('', toRaw(form.passwordForm))
            .then(res => {
              if (res) {
                message.success('修改密码成功');
                clearInterval(state.timerPsw);
                resetFields();
                // 请求成功或者失败时关闭
                state.visible = false;
                state.loading = false;
                state.btnPsw = { disabled: false, title: '获取验证码' };
              }
            })
            .catch(() => {
              state.loading = false;
            });
        });
      },
      handleCancel() {
        clearInterval(state.timerPsw);
        clearInterval(state.timerT);
        resetFields();
        // 为了拿默认号码
        form.passwordForm.mobile = state.dataSource.description;
        getUserInfo();
        state.btnPsw = { disabled: false, title: '获取验证码' };
      },
      handlePhoneOk() {
        safeRef.value.validate().then(() => {
          let parmas = {
            userId: userId,
            verifyCode: state.safeForm.code,
            mobile: state.safeForm.account,
            areaCode: '86',
          };

          personalApi.updateUserMobile('', parmas).then(res => {
            if (res) {
              message.success('修改手机成功');
              // 调取安全设置列表接口
              getUserInfo();
              state.visiblePE = false;
              // 确认需要清空验证码和清除定时器
              clearInterval(state.timerT);
              state.btnT = { disabled: false, title: '获取验证码' };
              state.safeForm = { account: '', code: '', oldCode: '' };
            }
          });
        });
      },
      handlePassword() {
        passwordRef.value.validate().then(() => {
          state.confirmLoading = true;
          personalApi
            .updatePassword({
              ...form.passwordForm,
              id: form.id,
            })
            .then(res => {
              if (res) {
                localStorage.clear();
                location.reload();
              }
            })
            .finally(() => {
              state.confirmLoading = false;
            });
        });
      },
      handleSafe() {
        safeRef.value.validate().then(() => {
          state.confirmLoading = true;
          loginApi
            .verifyCode(form.safeForm)
            .then(data => {
              if (data) {
                form[state.visible] = form.safeForm.account;
                personalApi
                  .update('personal:update', form)
                  .then(res => {
                    if (res) {
                      localStorage.clear();
                      location.reload();
                    }
                  })
                  .finally(() => {
                    state.confirmLoading = false;
                  });
              } else {
                state.confirmLoading = false;
                message.error('验证失败，请重新获取验证码');
              }
            })
            .catch(() => (state.confirmLoading = false));
        });
      },
      getValue(value) {
        form.avatar = value;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
  padding: 16px 48px 16px 16px;
}
.iss-title {
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 12px;
}
.iss-titleT {
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 24px;
}
.contactFrequency {
  :deep(.ant-tabs .ant-tabs-bar) {
    border-bottom: none;
  }
}
.minHeigth {
  min-height: calc(100vh - 124px);
}
.gotoBack {
  text-align: right;
  padding: 12px;
  color: #1d2129;
  cursor: pointer;
}
.iss-main /deep/ .ant-list {
  border-top: 1px solid #f0f0f0;
}
.update {
  border: 1px solid #ff7b00;
}
.unbinding {
  border: 1px solid #ff7b00;
}
.logout {
  color: #ffffff;
  background: #fa5151;
}
.notice {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .noticeL {
    width: 70%;
    .noticeTO {
    }
    .noticeTT {
    }
  }
  .noticeR {
    width: 70%;
    text-align: right;

    .dropDown {
      border-radius: 4px;
      opacity: 1;
      background: #ffffff;

      margin-left: 24px;
      position: relative;
      .itemVal {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        .iconsImg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
      .dropDownList {
        position: absolute;
        //left: -110px;
        left: -105px;
        top: 36px;
        z-index: 2;
        background: #ffffff;
        width: 200px;
        border: 0.5px solid #eeeeee;
        border-radius: 4px;
        cursor: pointer;
        text-align: left;
        box-shadow: 0px -1px 6px #c9cdd4;
        .dropDownItem {
          border-bottom: 1px solid #eeeeee;
          padding: 12px;
        }
      }
    }
  }
  .rigthR {
    display: flex;
    justify-content: right;
  }
}
.borBor {
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  padding: 24px 0px;
}
</style>
